var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.feedInSystem.reactive_power_type)?_c('RowItem',{staticClass:"row-item",attrs:{"first":"","title":_vm.$t('feed_in_system_details.reactive_power_type'),"editable":false}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.feedInSystem.reactive_power_type)}})]):_vm._e(),_c('RowItem',{staticClass:"row-item",attrs:{"first":!_vm.feedInSystem.reactive_power_type,"field":"updatePVInstallationAddress","title":("<strong>" + (_vm.$t(
      'feed_in_system_details.measurement_location'
    )) + "</strong> <br> " + (_vm.$t(
      'feed_in_system_details.measurement_location_fields'
    ))),"editable":false},on:{"edit":function($event){return _vm.$emit('edit-component', {
        component: 'dummy component name',
        location: 'MeasurementData',
        properties: _vm.feedInSystem,
      })}}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.presentableFeedInSystem.measurementLocation)}})]),_c('RowItem',{staticClass:"row-item",attrs:{"last":"","field":"updatePVMarketLocation","title":("<strong>" + (_vm.$t(
      'feed_in_system_details.market_location'
    )) + "</strong><br>" + (_vm.$t('feed_in_system_details.market_location_fields'))),"editable":_vm.feedInSystem.status === _vm.feedInStatuses.fulfilled_conf.value
        ? true
        : false},on:{"edit":function($event){return _vm.$emit('edit-component', {
        component: 'MarketLocationComponent',
        location: 'MeasurementData',
        properties: _vm.feedInSystem,
      })}}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.presentableFeedInSystem.marketLocation)}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }